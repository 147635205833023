import { useEffect, useState } from 'react';

function serialize<V>(v: V | null): string | null {
  if (v) {
    return JSON.stringify(v);
  } else {
    return null;
  }
}

function deserialize<V = any>(s: string | null): V | null {
  if (s) {
    return JSON.parse(s);
  } else {
    return null;
  }
}

type StorageArea = 'session' | 'local';

export function useStorage<V>(
  storageArea: StorageArea,
  key: string,
  defaultValue: V = null
): [V | null, (v: V) => void] {
  const [state, setState] = useState<V | null>(defaultValue);

  const storage = storageArea === 'session' ? sessionStorage : localStorage;

  useEffect(() => {
    const value = deserialize(storage.getItem(key));

    if (value !== state) {
      setState(value);
    }
  });

  useEffect(() => {
    const listener = (ev: StorageEvent) => {
      if (ev.storageArea === storage) {
        if (ev.key === null) {
          setState(null);
        } else if (ev.key === key) {
          setState(deserialize(ev.newValue));
        }
      }
    };

    window.addEventListener('storage', listener);

    return () => {
      window.removeEventListener('storage', listener);
    };
  });

  const setValue = (v: V | null) => {
    const item = serialize(v);

    if (item) {
      storage.setItem(key, item);
    } else {
      storage.removeItem(key);
    }

    setState(v);
  };

  return [state, setValue];
}
