/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import { useLocation } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { useDarkMode } from '../hooks/use-dark-mode';
import Header from './header/header';
import MainMenu from './main-menu';
import Reset from './reset';
import Theme, { darkTheme, lightTheme } from './theme';

const Page = styled.div`
  min-height: 100vh;
  background: var(--background-color);

  & > * {
    position: relative;
  }

  &:before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;
    right: 0;

    height: clamp(30rem, 100%, 100vh);

    opacity: 0.05;

    background: linear-gradient(to bottom, black, transparent);
  }
`;

const Layout: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const location = useLocation();
  const { site } = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  const isRoot = location.pathname === '/';

  const [menuState, setMenuState] = useState(false);
  const toggleMenu = useCallback(() => {
    setMenuState(!menuState);
  }, [menuState, setMenuState]);

  const [theme, setTheme] = useDarkMode('session', 'light');

  return (
    <>
      <Reset />
      <Theme
        colors={theme === 'dark' ? darkTheme : lightTheme}
        invert={theme === 'dark'}
      >
        <Header
          toggleMenu={toggleMenu}
          menuState={menuState}
          theme={theme}
          setTheme={setTheme}
          title={site.siteMetadata.title}
        />
        <Page>{children}</Page>
        <MainMenu open={menuState} />
      </Theme>
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
