import React, { SyntheticEvent, MouseEventHandler, Props } from 'react';
import styled, { css } from 'styled-components';

const MenuIconBar = styled.div`
  height: 2px;
  background: var(--text-color);
  border-radius: 1px;

  transition: transform 0.25s ease-in-out;
`;

const MenuButton = styled.button.attrs((props: { menuIsOpen?: boolean }) => ({
  ...props,
  menuIsOpen: props.menuIsOpen ?? false,
}))`
  --button-size: var(--header-button-size);
  --button-padding: calc(5rem / 16) 0;

  display: grid;
  grid-template: auto, auto, auto / 1fr;
  grid-gap: 3px;

  width: var(--button-size);
  padding: var(--button-padding);

  background: none;
  border: none;
  outline: none;

  cursor: pointer;

  ${MenuIconBar}:nth-child(1) {
    ${(props) =>
      props.menuIsOpen
        ? css`
            transform: translateY(5px) rotate(45deg);
          `
        : css`
            transform: none;
          `}
  }

  ${MenuIconBar}:nth-child(2) {
    ${(props) =>
      props.menuIsOpen
        ? css`
            transform: scaleX(0);
          `
        : css`
            transform: none;
          `}
  }

  ${MenuIconBar}:nth-child(3) {
    ${(props) =>
      props.menuIsOpen
        ? css`
            transform: translateY(-5px) rotate(-45deg);
          `
        : css`
            transform: none;
          `}
  }
`;

const MenuToggle: React.FC<{
  onClick?: MouseEventHandler<HTMLButtonElement>;
  menuIsOpen?: boolean;
}> = ({ onClick, menuIsOpen = false }) => (
  <>
    <MenuButton onClick={onClick} menuIsOpen={menuIsOpen}>
      <MenuIconBar />
      <MenuIconBar />
      <MenuIconBar />
    </MenuButton>
  </>
);

export default MenuToggle;
