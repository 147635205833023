import { graphql, Link, useStaticQuery } from 'gatsby';
import React, { useCallback, useEffect } from 'react';
import styled, { css, keyframes } from 'styled-components';

import { Markdown } from './markdown';
import { useDebounce } from '../hooks/use-debounce';
import { useFragmentList } from '../hooks/use-fragments';

interface MainMenuContainerProps {
  open?: boolean;
  displayed?: boolean;
}

const menuReveal = keyframes`
  from {
    opacity: 0;
    transform: scale(1.1);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const MainMenuContainer = styled.aside`
  --main-menu-top-gap: clamp(85px, 10vh, 200px);
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%;
  /* padding-top: var(--main-menu-top-gap); */
  padding-bottom: 80px;
  place-items: center;

  background: var(--background-color);

  --reveal-duration: 0.25s;

  transition: opacity var(--reveal-duration) ease-in-out,
    transform var(--reveal-duration) ease-in-out;

  overflow-x: hidden;
  overflow-y: auto;

  ${({ displayed }: MainMenuContainerProps) =>
    displayed
      ? css`
          display: grid;
          animation: ${menuReveal} var(--reveal-duration) ease-in-out 1;
        `
      : css`
          display: none;
        `}

  ${({ open }: MainMenuContainerProps) =>
    open
      ? css`
          opacity: 1;
          transform: scale(1);
        `
      : css`
          opacity: 0;
          transform: scale(1.1);
        `}
`;

const MainMenuTopCard = styled.div`
  position: sticky;
  top: 0;
  left: 0;
  height: var(--main-menu-top-gap);
  width: 100%;
  background: var(--background-color);
`;

interface MainMenuProps {
  open?: boolean;
}

const MainMenuContent = styled.div`
  display: grid;
  align-content: center;
  grid-template: ' fragments divider blog ' 1fr / 1fr 1px 1fr;

  @media screen and (max-width: 640px) {
    grid-template:
      ' fragments ' auto
      ' divider   ' 1px
      ' blog      ' auto / 1fr;
  }

  margin: 0 auto;
  gap: var(--content-padding);
  max-width: var(--content-size);
  padding-left: var(--content-padding);
  padding-right: var(--content-padding);
  padding-bottom: 2rem;
`;

const MainMenuFragments = styled(Markdown)`
  grid-area: fragments;
  display: flex;
  flex-wrap: wrap;

  ul {
    flex: 0 0 50%;
  }
`;

const MainMenuBlog = styled.div`
  grid-area: blog;

  h2 {
    font-family: jaf-lapture-caption, serif;
    font-weight: 600;
    font-style: normal;
    font-size: calc(36rem / 16);
    line-height: 1.15;
    margin-bottom: 0.5em;
    color: var(--highlight-background-color);
  }

  ul {
    font-size: 1rem;
  }

  li {
    margin-bottom: 0.5rem;
  }
`;

const Divider = styled.div`
  background-color: var(--text-color);
`;

const MainMenu = ({ open }: MainMenuProps) => {
  const getDebounce = useCallback((o: boolean): number => {
    if (o) {
      return 0;
    } else {
      return 250;
    }
  }, []);

  const displayed = useDebounce(open ?? false, getDebounce);

  const { latestArticles } = useStaticQuery(graphql`
    {
      latestArticles: allMarkdownRemark(
        filter: { fileAbsolutePath: { glob: "**/blog/**" } }
        sort: { fields: frontmatter___date, order: DESC }
        limit: 5
      ) {
        edges {
          node {
            frontmatter {
              title
              path
            }
          }
        }
      }
    }
  `);

  const articles = latestArticles.edges.map(({ node }) => node.frontmatter);
  const fragmentList = useFragmentList();

  useEffect(() => {
    document.body.setAttribute(
      'style',
      `overflow-y: ${open ? 'hidden' : 'auto'};`
    );
  }, [open]);

  return (
    <MainMenuContainer open={open} displayed={displayed}>
      <MainMenuTopCard/>
      <MainMenuContent>
        <MainMenuFragments dangerouslySetInnerHTML={{ __html: fragmentList }} />
        <Divider />
        <MainMenuBlog>
          <h2>Blog</h2>
          <ul>
            {articles.map(({ title, path }) => (
              <li key={path}>
                <Link to={path}>{title}</Link>
              </li>
            ))}
          </ul>
        </MainMenuBlog>
      </MainMenuContent>
    </MainMenuContainer>
  );
};

export default MainMenu;
