import React, { ReactNode } from "react";
import { createGlobalStyle } from "styled-components";

export interface Colors {
  textColor: string;
  backgroundColor: string;
  highlightTextColor: string,
  highlightBackgroundColor: string,
}

export const lightTheme: Colors = {
  textColor: "#444",
  backgroundColor: "#F0ECE8",
  highlightTextColor: "#fff",
  highlightBackgroundColor: "#222",
};

export const darkTheme: Colors = {
  textColor: "#fff",
  backgroundColor: "#222",
  highlightTextColor: "#222",
  highlightBackgroundColor: "#fff",
};

interface ThemeProps {
  colors: Colors;
  invert: number;
}

const ThemeStyles = createGlobalStyle`
  :root {
    --text-color: ${({ colors }: ThemeProps) => colors.textColor};
    --background-color: ${({ colors }: ThemeProps) => colors.backgroundColor};

    --highlight-text-color: ${({ colors }: ThemeProps) => colors.highlightTextColor};
    --highlight-background-color: ${({ colors }: ThemeProps) => colors.highlightBackgroundColor};

    --header-offset-top: calc(40rem / 16);
    --header-offset-side: calc(30rem / 16);
    --header-button-size: calc(20rem / 16);

    @media screen and (max-width: 400px) {
      --header-offset-side: calc(20rem / 16);
    }

    --content-size: 1120px;
    --content-padding: 15px;

    --invert: ${(({ invert }: ThemeProps) => invert)};

    @media screen and (min-width: 500px) {
      --content-padding: 80px;
    }

    color: var(--text-color);
  }

  ::selection {
    background-color: var(--highlight-background-color);
    color: var(--highlight-text-color);
  }

  html {
    font-family: classica-pro, serif;
    font-weight: 400;
    font-style: normal;
  }

  body {
    overflow-y: scroll;
  }
`;

export interface Props {
  children?: ReactNode;
  colors?: Colors;
  invert?: boolean;
}

const Theme: React.FC<Props> = ({ children, colors = darkTheme, invert = false }: Props) => (
  <>
    <ThemeStyles colors={colors} invert={invert ? 1 : 0} />
    {children}
  </>
);

export default Theme;
