import { faFacebookF, faInstagram } from '@fortawesome/free-brands-svg-icons';
import React from 'react';
import styled from 'styled-components';

import { Mode } from '../../hooks/use-dark-mode';
import { HeaderLink } from './header-link';
import MenuToggle from './menu-toggle';
import PageTitle from './page-title';
import ThemeToggle from './theme-toggle';

const Container = styled.header`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;

  padding-top: var(--header-offset-top);
  padding-left: var(--header-offset-side);
  padding-right: var(--header-offset-side);

  z-index: 10;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const LinksContainer = styled.div`
  --gap: 5px;

  @media screen and (min-width: 380px) {
    --gap: 15px;
  }

  @media screen and (min-width: 640px) {
    --gap: 30px;
  }

  display: flex;
  gap: var(--gap);

  @media screen and (min-width: 500px) {
    margin-left: calc((38px + var(--gap)) * -2);
  }
`;

const Header: React.FC<{
  toggleMenu(): void;
  menuState?: boolean;
  setTheme(mode: Mode): void;
  theme: Mode;
  title: string;
}> = ({ toggleMenu, setTheme, theme, title, menuState = false }) => {
  return (
    <Container>
      <MenuToggle onClick={toggleMenu} menuIsOpen={menuState} />
      <PageTitle>{title}</PageTitle>
      <LinksContainer>
        <HeaderLink
          href={'https://www.instagram.com/relikty.minulosti/'}
          icon={faInstagram}
        ></HeaderLink>
        <HeaderLink
          href={'https://www.facebook.com/relikty.minulosti'}
          icon={faFacebookF}
        ></HeaderLink>
        <ThemeToggle setTheme={setTheme} theme={theme} />
      </LinksContainer>
    </Container>
  );
};

export default Header;
