import { useCallback, useEffect, useState } from 'react';

export function useMedia(mediaQuery: string): boolean {
  const [matches, setMatches] = useState(false);

  const listener = useCallback(
    (ev: MediaQueryListEvent) => {
      setMatches(ev.matches);
    },
    [setMatches]
  );

  //
  // A check for presence of matchMedia is fine since it will never change.
  // The browser will either support the feature or not, so it's like if we're
  // using two distinct implementations of the hook.
  //
  if (window.matchMedia) {
    useEffect(() => {
      const match = window.matchMedia(mediaQuery);

      match.addListener(listener);
      setMatches(match.matches);

      return () => {
        match.removeListener(listener);
      };
    }, [mediaQuery]);
  }

  return matches;
}
