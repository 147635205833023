import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';

const HeaderAnchor = styled.a`
  --size: 38px;

  width: var(--size);
  height: var(--size);

  display: grid;
  place-items: center;

  border: none;
  border-radius: 50%;
  outline: none;

  --outline-size: 0.5rem;
  padding: var(--outline-size);
  margin: calc(0rem - var(--outline-size));

  color: var(--text-color);
  background-color: transparent;

  cursor: pointer;

  transition: color 0.15s, background 0.15s;

  &:hover,
  &:focus,
  &:active {
    color: var(--background-color);
    background-color: var(--text-color);
  }
`;

export const HeaderLink: React.FC<{ href: string; icon: IconDefinition }> = ({
  href,
  icon,
}) => {
  return (
    <HeaderAnchor href={href} target="_blank">
      <FontAwesomeIcon icon={icon} size="lg"></FontAwesomeIcon>
    </HeaderAnchor>
  );
};
