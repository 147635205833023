const mod =
  typeof window !== `undefined`
    ? require('./use-storage.browser')
    : require('./use-storage.server');

export type StorageArea = 'local' | 'session';

export const useStorage = mod.useStorage as <V>(
  area: StorageArea,
  key: string,
  defaultValue?: V
) => [V | null, (v: V) => void];

