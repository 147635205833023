import { graphql, useStaticQuery } from 'gatsby';

export function useFragmentList(): string {
  const { fragmentList } = useStaticQuery(graphql`
    {
      fragmentList: markdownRemark(
        fileAbsolutePath: { glob: "**/content/fragment-list.md" }
      ) {
        html
      }
    }
  `);

  return fragmentList.html;
}
