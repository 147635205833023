import { navigate } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const PageTitle = styled.h1.attrs({
  onClick: () => navigate('/'),
})`
  flex: 0 0 auto;

  font-family: jaf-lapture-caption, serif;
  font-weight: 600;
  font-style: normal;
  font-size: calc(21rem / 16);
  line-height: 1.15em;

  cursor: pointer;
`;

export default PageTitle;
