import React, { useCallback } from 'react';
import styled from 'styled-components';

import { Mode } from '../../hooks/use-dark-mode';

const ThemeButton = styled.button.attrs({ type: 'button' })`
  --size: 38px;

  width: var(--size);
  height: var(--size);

  display: grid;
  place-items: center;

  border: none;
  border-radius: 50%;
  outline: none;

  --outline-size: 0.5rem;
  padding: var(--outline-size);
  margin: calc(0rem - var(--outline-size));

  color: var(--text-color);
  background-color: transparent;

  cursor: pointer;

  transition: color 0.15s, background 0.15s;

  @media screen and (max-width: 380px) {
    display: none;
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--background-color);
    background-color: var(--text-color);
  }

  & > svg {
    transition: transform 0.25s;
    transform: rotate(
      ${({ theme }: { theme: Mode }) => (theme === 'dark' ? '-180deg' : '0')}
    );
  }
`;

interface Props {
  setTheme: (mode: Mode) => void;
  theme: Mode;
}

const ThemeToggle = ({ setTheme, theme }: Props) => {
  const toggleTheme = useCallback(() => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  }, [setTheme]);

  return (
    <ThemeButton onClick={toggleTheme} theme={theme}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        viewBox="0 0 21 22"
        fill="none"
      >
        <path
          d="M0.328125 11.125C0.328125 16.7441 4.88086 21.2969 10.5 21.2969C16.0781 21.2969 20.6719 16.7441 20.6719 11.125C20.6719 5.54688 16.0781 0.953125 10.5 0.953125C4.88086 0.953125 0.328125 5.54688 0.328125 11.125ZM10.5 18.6719V3.57812C14.6426 3.57812 18.0469 6.98242 18.0469 11.125C18.0469 15.3086 14.6426 18.6719 10.5 18.6719Z"
          fill="currentColor"
        />
      </svg>
    </ThemeButton>
  );
};

export default ThemeToggle;
