import { useEffect, useState } from 'react';

export type GetTime<V> = (v: V) => number;

export function useDebounce<V>(val: V, time: number | GetTime<V>): V {
  const [output, setOutput] = useState<V | undefined>(undefined);

  useEffect(() => {
    if (!output) {
      setOutput(val);
    } else {
      const duration = typeof time === 'number' ? time : time(val);
      const futureOutput = val;

      const timeout = setTimeout(() => setOutput(futureOutput), duration);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [val, time]);

  return output;
}
