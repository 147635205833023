import styled, { css } from 'styled-components';

const headingStyle = css`
  font-family: jaf-lapture-caption, serif;
  font-weight: 600;
  font-style: normal;
  line-height: 1.15;
  margin-bottom: 0.5em;
  color: var(--highlight-background-color);
`;

export const h1style = css`
  ${headingStyle};

  @media screen and (min-width: 500px) {
    font-size: calc(52rem / 16);
  }

  font-size: calc(40rem / 16);
`;

export const h2style = css`
  ${headingStyle};

  @media screen and (min-width: 500px) {
    font-size: calc(36rem / 16);
  }

  font-size: calc(32rem / 16);
`;

export const Markdown = styled.div`
  line-height: 1.6;
  font-size: 1rem;
  color: var(--text-color);

  h1,
  h2 {
    font-family: jaf-lapture-caption, serif;
    font-weight: 600;
    font-style: normal;
    line-height: 1.15;
  }

  h1 {
    ${h1style};
    display: flex;
    flex-wrap: wrap;

    & + p {
      @media screen and (min-width: 500px) {
        font-size: calc(21rem / 16);
      }
    }

    &:before {
      --size: 96px;

      @media screen and (min-width: 680px) {
        --size: 64px;
      }

      content: '';
      flex: 0 0 auto;

      position: relative;
      top: -6px;
      width: var(--size);
      height: var(--size);
      display: inline-block;
      vertical-align: middle;
      margin-right: 16px;

      background: url('/symbol-96x96.png');
      background-size: cover;
      filter: invert(var(--invert));
    }
  }

  h2 {
    ${h2style};
    font-size: calc(36rem / 16);
  }

  p {
    text-align: justify;
    margin-bottom: 0.5em;

    @media screen and (min-width: 500px) {
      font-size: calc(18rem / 16);
    }
  }

  ul {
    margin-bottom: 1em;
  }
`;
