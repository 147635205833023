import { useMedia } from './use-media/use-media';
import { useStorage, StorageArea } from './use-storage/use-storage';

export type Mode = 'light' | 'dark';

export function useDarkMode(
  overrideStorage: StorageArea = 'session',
  defaultMode?: Mode
): [Mode, (mode: Mode) => void] {
  const [stored, setStored] = useStorage<Mode>(overrideStorage, 'dark_mode');

  const prefersDarkMode = useMedia('(prefers-color-scheme: dark)');

  return [
    stored ?? defaultMode ?? (prefersDarkMode ? 'dark' : 'light'),
    setStored,
  ];
}
